import React, { useState, useEffect, useRef } from 'react'

import { ReactComponent as IconVirtualAssistantAnamnesisRecord } from "../../../../assets/icon-virtual-assistant-anamnesis-record.svg"
import { ReactComponent as IconRecordDelete } from "../../../../assets/icon-virtual-assistant-anamnesis-record-delete.svg"
import { ReactComponent as IconRecordInit } from "../../../../assets/icon-virtual-assistant-anamnesis-record-init.svg"
import { ReactComponent as IconRecordPause } from '../../../../assets/icon-virtual-assistant-anamnesis-record-pause.svg'
import { ReactComponent as IconRecordStop } from '../../../../assets/icon-virtual-assistant-anamnesis-record-stop.svg'
import { ReactComponent as IconRecordPermission } from '../../../../assets/icon-virtual-assistant-anamnesis-record-permission.svg'
import { ReactComponent as IconRecordMicro } from '../../../../assets/icon-virtual-assistant-anamnesis-record-micro.svg'
import { ReactComponent as IconRecordBar } from '../../../../assets/icon-virtual-assistant-anamnesis-record-bar.svg'
import { ReactComponent as IconRecordError } from '../../../../assets/icon-virtual-assistant-anamnesis-record-error.svg'
import { ReactComponent as IconRecordSaveConfirmation } from '../../../../assets/icon-virtual-assistant-anamnesis-save-confirmation.svg'
import { ReactComponent as IconRecordDeleteConfirmation } from '../../../../assets/icon-virtual-assistant-anamnesis-delete-confirmation.svg'
import { ReactComponent as IconPrimaryEdit } from "../../../../assets/icon-primary-edit.svg"
import { ReactComponent as IconSave } from "../../../../assets/icon-save.svg"

import VSEmpty from '../../../../components/vsEmpty/VSEmpty';
import { saveOnTracker, toBase64 } from '../../../../utils'
import { CircularProgress, TextField } from '@mui/material';
import { APP_DEVICE, APP_NAME, APP_VERSION } from '../../../../constants'

const AppointmentFormAnamnesisRecordSaveConfirmation = ({ onConfirm, onCancel }) => (
    <div className="appointment-preview-confirmation blue">
        <div className="confirmation-image-wrap">
            <IconRecordSaveConfirmation />
        </div>
        <div className="confirmation-title mt-2">Tem certeza que deseja salvar essa gravação revisada?</div>
        <div className="confirmation-text mt-2">Ao salvar, iremos adicionar a gravação revisada junto com os dados que estão escritos no campo de motivos da consulta ou anamnese.</div>
        <button className={`vsbox-btn vsbox-btn-primary w-100 text-normal mt-3`} onClick={() => {
            onConfirm()
        }}>
            Salvar
        </button>
        <button className={`vsbox-btn vsbox-btn-warning w-100 text-normal mt-3`} onClick={() => {
            onCancel()
        }}>
            Cancelar
        </button>
    </div>
)

const AppointmentFormAnamnesisRecordDeleteConfirmation = ({ onDelete, onCancel }) => (
    <div className="appointment-preview-confirmation red">
        <div className="confirmation-image-wrap">
            <IconRecordDeleteConfirmation />
        </div>
        <div className="confirmation-title mt-2">Tem certeza que deseja excluir essa gravação revisada?</div>
        <div className="confirmation-text mt-2">Os dados não poderão ser recuperados e esta operação não poderá ser desfeita.</div>
        <button className={`vsbox-btn vsbox-btn-negative w-100 text-normal mt-3`} onClick={() => {
            onDelete()
        }}>
            Excluir
        </button>
        <button className={`vsbox-btn vsbox-btn-warning w-100 text-normal mt-3`} onClick={() => {
            onCancel()
        }}>
            Cancelar
        </button>
    </div>
)

const AppointmentFormAnamnesisRecordPreview = (props) => {

    const {
        clinic,
        patient,
        appointment,
        onSaveRecording,
        trackerSource,
        trackerSourceDetail
        // aiCache,
        // hasDifferentialDiagnosisChanged,
        // getDifferentialDiagnosis
    } = props

    const recordingMaxSeconds = 60 * 80 // 80 min
    const mediaRecorderConstraints = { audio: true }
    let chunks = [] // doesnt work with const / useState for some reason

    const [ waitingForMediaRecorder, setWaitingForMediaRecorder ] = useState(false)
    const [ isRecording, setIsRecording ] = useState(false)
    const [ isPaused, setIsPaused ] = useState(false)
    const [ isTranscribing, setIsTranscribing ] = useState(false)
    const [ transcribedText, setTranscribedText ] = useState(null)
    const [ editedTranscribedText, setEditedTranscribedText ] = useState(null)
    const [ isEditingTranscription, setIsEditingTranscription ] = useState(false)
    const [ error, setError ] = useState(null)
    const [ genericLoading, setGenericLoading ] = useState(false)

    const [ confirmationscreen, setConfirmationScreen ] = useState(null)

    const [ mediaRecorder, setMediaRecorder ] = useState(null)

    // For Audio Animation
    const [ audioCtx, setAudioCtx ] = useState(new AudioContext())
    const [ audioTimer, setAudioTimer ] = useState(0)
    const audioBar = useRef(null)
    const animationRef = useRef(null)
    const isMounted = useRef(null)

    const isPermissionDenied = () => error && error?.name && error?.message && (error?.name?.indexOf('NotAllowedError') !== -1 || error?.message.indexOf('Permission denied') !== -1)

    const GetAudioTranscription = async (audioBlob, mimeType) => {
        // const audioURL = window.URL.createObjectURL(audioBlob)
        // window.open(audioURL, "_blank")

        // const base64 = await toBase64(audioBlob)

        // const parseFile = new Parse.File("audio.webm", { base64: base64 })
        // let _blob = await fetch(audioURL).then(r => r.blob());
        // const file = new File([audioBlob], "audio.webm");
        const extension = mimeType?.split('/')[1]?.split(';')[0]
        const parseFile = new Parse.File(`audio.${extension}`, audioBlob)
        const savedFile = await parseFile.save()
        .catch(error => {
            console.error(error)
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não foi possível processar o audio"
            })
            setError({
                message: error,
                function: "GetAudioTranscription"
            })
        })

        const aiCache = await Parse.Cloud.run("aiAudioTranscription", {
            clinicId: clinic.objectId || clinic.id,
            patientId: patient.id,
            audio: savedFile,
            appVersionName: APP_VERSION,
            deviceType: APP_DEVICE,
            appContentType: APP_NAME
        })
        .catch(error => {
            console.error(error)
            Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Não foi possível processar a gravação"
            })
            setError({
                message: error,
                function: "GetAudioTranscription"
            })
        })

        saveOnTracker("Concluiu", "Assistente", "Gravar Anamnese", null, trackerSource ? trackerSource : "Consulta", null, trackerSourceDetail, JSON.stringify({
            patientId: patient?.id || patient?.objectId,
            appointmentId: appointment?.id ? appointment?.id : 'novo',
            aiCacheId: aiCache?.id || aiCache?.objectId,
            cached: aiCache?.get("cached")
        }))

        const text = aiCache.get('response')?.text

        if (!text) {
            setError({
                message: error,
                function: "GetAudioTranscription"
            })
        }

        setTranscribedText(text)
        setEditedTranscribedText(text)
        setIsTranscribing(false)
    }

    const OnMediaRecorderStop = ({ target }) => {
        setIsRecording(false)
        setIsPaused(false)
        setError(null)

        const blob = new Blob(chunks, { type: target.mimeType })
        chunks = []
        
        // const audioURL = window.URL.createObjectURL(blob)
        // window.open(audioURL, "_blank")
        GetAudioTranscription(blob, target.mimeType)
    }

    const OnMediaRecorderDataAvailable = (e) => {
        chunks.push(e.data)
    }

    const setAudioAnimation = stream => {
        const source = audioCtx.createMediaStreamSource(stream)
        
        const analyser = audioCtx.createAnalyser()
        analyser.fftSize = 512
        const bufferLength = analyser.frequencyBinCount
        const dataArray = new Uint8Array(bufferLength)
        source.connect(analyser);

        let seconds = audioTimer
		let currentTime = (new Date()).getTime();
        let lastTime = (new Date()).getTime();

        const draw = () => {
            if (!isRecording) {
                return
            }

            analyser.getByteTimeDomainData(dataArray)
            let normSamples = [...dataArray].map(e => e / 128 - 1)
            let sum = 0;

            for (let i = 0; i < normSamples.length; i++) {
                sum += normSamples[i] * normSamples[i]
            }

            let volume = Math.sqrt(sum / normSamples.length)
            if (audioBar?.current?.style) {
                const currentWidth = audioBar.current.parentNode.clientWidth
                const relativeVolume = volume * currentWidth
                const percentIncrease = (relativeVolume / currentWidth) + 2
                audioBar.current.style.width = (relativeVolume * percentIncrease) + 'px'
            }

            currentTime = (new Date()).getTime();
            if (currentTime - lastTime >= 1000) {
                lastTime = currentTime;
                seconds++
                setAudioTimer(seconds);

                if (seconds >= recordingMaxSeconds) {
                    stopRecording()
                }
            }

            animationRef.current = requestAnimationFrame(draw)
        }
        animationRef.current = requestAnimationFrame(draw)
    }

    const onMediaRecorderSuccess = (stream) => {
        setWaitingForMediaRecorder(false)
        setTranscribedText(null)
        setEditedTranscribedText(null)
        setError(null)
        const _mediaRecorder = new MediaRecorder(stream)
        _mediaRecorder.start()
        _mediaRecorder.onstop = OnMediaRecorderStop
        _mediaRecorder.ondataavailable = OnMediaRecorderDataAvailable
        setMediaRecorder(_mediaRecorder)
        setIsRecording(true)
        setIsPaused(false)
        setIsTranscribing(false)
        saveOnTracker("Clicou", "Assistente", "Gravar Anamnese", null, "Consulta", null, null, {
            appointmentId: appointment?.id ? appointment?.id : 'novo',
            patientId: patient?.id || patient?.objectId,
            action: "Iniciar"
        })
    }

    const onMediaRecorderError = (err) => {
        console.error("The following error occured: " + err)
        setError(err)
        setWaitingForMediaRecorder(false)
        setIsRecording(false)
        setIsPaused(false)
        setIsTranscribing(false)
        setTranscribedText(null)
        setEditedTranscribedText(null)
    }

    const resetEverything = () => {
        setWaitingForMediaRecorder(false)
        setIsRecording(false)
        setIsPaused(false)
        setIsTranscribing(false)
        setTranscribedText(null)
        setEditedTranscribedText(null)
        setError(null)
        mediaRecorder?.stop()
        mediaRecorder?.stream.getTracks().forEach(track => {
            track.stop()
            track.enabled = false
        })
        // const _mediaRecorder = new MediaRecorder(stream)
    }

    const stopRecording = () => {
        if (isMounted.current) {
            mediaRecorder.stop()
            mediaRecorder.stream.getTracks().forEach(track => {
                track.stop()
                track.enabled = false
            })
            setIsTranscribing(true)
            saveOnTracker("Clicou", "Assistente", "Gravar Anamnese", null, "Consulta", null, null, {
                appointmentId: appointment?.id ? appointment?.id : 'novo',
                patientId: patient?.id || patient?.objectId,
                action: "Finalizar"
            })
        }
    }

    useEffect(() => {
        if (isMounted.current) {
            setAudioTimer(0);
            if (isRecording) {
                setAudioAnimation(mediaRecorder.stream)
            } else {
                cancelAnimationFrame(animationRef.current)
            }
        }
    }, [ isRecording ])

    useEffect(() => {
        if (isMounted.current) {
            if (!mediaRecorder || mediaRecorder.state == "inactive") {
                return
            }
    
            if (isPaused) {
                mediaRecorder.pause()
                cancelAnimationFrame(animationRef.current)
            } else {
                mediaRecorder.resume()
                setAudioAnimation(mediaRecorder.stream)
            }
        }
    }, [ isPaused ])

    useEffect(() => {
        const vsDrawerInfo = document.querySelector('.vsdrawer-info')
        if (vsDrawerInfo) {
            vsDrawerInfo.scrollTop = 0
        }
    }, [ confirmationscreen ])

    useEffect(() => {
        isMounted.current = true
        if (window?.navigator?.mediaDevices?.getUserMedia) {
            setIsRecording(false)
            setIsPaused(false)
            setIsTranscribing(false)
            setError(null)
        } else {
            console.log("MediaDevices.getUserMedia() not supported on your browser!")
        }
        return () => {
            isMounted.current = false
        }
    }, [])

    const formatTimer = timer => {
        const formatter = new Intl.NumberFormat("pt-BR", {
            minimumIntegerDigits: 2
        })

        return <>{Math.floor(timer / 60)}:{formatter.format(timer % 60)}</>
    }

    const stickyButtonsBottomStyle = document.querySelector('.vsdrawer-footer')?.offsetHeight + 'px'

    return (
        <div className={`anamnesis-record-preview row flex-column align-items-end gx-0 ${confirmationscreen ? 'position-static' : ''}`}>
            {confirmationscreen}
            {/* <div className="col-12 ia-warning-text px-3 py-2">
                <IconSimpleWarning className="me-2" width="16" height="16" fill="#666666" />
                A IA pode produzir resultados imprecisos. <a className="link" href="https://pl-vetsmart.zendesk.com/hc/pt-br/articles/29652456409883-Assistente-Vetsmart" target="_blank">Saiba mais</a>.
            </div> */}
            <div className={`col-12 px-3 flex-grow-1 anamnesis-record-wrap`}>
                {genericLoading ? ( 
                    <div className="row data-loading pt-5 w-auto">
                        <div className="col-12">
                            <CircularProgress />
                        </div>
                    </div>
                ) : waitingForMediaRecorder || isPermissionDenied() ? (
                    <div className="row data-loading pt-5 w-auto">
                        <div className="col-12">
                            <IconRecordPermission />
                            <div className="recording-title mt-3">Permitir microfone para realizar a gravação</div>
                            <div className="recording-text mt-2">Para usar essa função do Assistente Vetsmart é preciso permitir acesso ao microfone do seu computador ou celular para gravar a anamnese.</div>
                        </div>
                    </div>
                ) : isTranscribing ? (
                    <div className="row data-loading pt-5 w-auto">
                        <div className="col-12">
                            <CircularProgress />
                            <div className="recording-title mt-3">Processando gravação</div>
                            <div className="recording-text mt-2">Por favor, aguarde alguns segundos enquanto analisamos sua gravação e preparamos sua anamnese</div>
                        </div>
                    </div>
                ) : error ? (
                    <div className="row data-loading pt-5 w-auto">
                        <div className="col-12">
                            <IconRecordError />
                            <div className="recording-title mt-3">Não conseguimos identificar sua gravação</div>
                            <div className="recording-text mt-2">Verifique se o microfone correto está selecionado e funcionando adequadamente. Problemas no dispositivo podem afetar o reconhecimento do áudio pelo Assistente Vetsmart.</div>
                            <button className={`vsbox-btn vsbox-btn-primary w-100 text-normal mt-3`} onClick={() => {
                                // saveOnTracker("Clicou", "Assistente", "Gravar Anamnese", null, "Consulta", null, null, {
                                //     appointmentId: appointment?.id ? appointment?.id : 'novo',
                                //     patientId: patient?.id || patient?.objectId,
                                //     action: "Não conseguimos identificar sua gravação"
                                // })
                                setError(null)
                            }}>
                                <IconVirtualAssistantAnamnesisRecord className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                Gravar Anamnese
                            </button>
                        </div>
                    </div>
                ) : transcribedText || isEditingTranscription ? (
                    <div className="row pt-4 align-items-stretch h-100">
                        <div className="col-12 d-flex flex-column">
                            <div className="recording-revised-title flex-shrink-0">Gravação revisada</div>
                            {isEditingTranscription ? (
                                <>
                                    <TextField
                                        className="mt-4 flex-shrink-0"
                                        placeholder="Anamnese"
                                        value={editedTranscribedText}
                                        onChange={({ target: { value } }) => setEditedTranscribedText(value)}
                                        size="small"
                                        variant="outlined"
                                        fullWidth
                                        multiline
                                    />
                                    <div 
                                        className="row pt-4 flex-grow-1 align-items-end gx-md-5 gx-4"
                                        style={{
                                            position: 'sticky',
                                            bottom: stickyButtonsBottomStyle
                                        }}
                                    >
                                        <div className="appointment-fixed-buttons col-12 py-3">
                                            <div className="row">
                                                <div className="col-6 pe-0">
                                                    <button className={`vsbox-btn vsbox-btn-warning px-0 w-100 text-normal`} onClick={() => {
                                                        setEditedTranscribedText(transcribedText)
                                                        setIsEditingTranscription(false)
                                                    }}>
                                                        Cancelar edição
                                                    </button>
                                                </div>
                                                <div className="col-6">
                                                    <button className={`vsbox-btn vsbox-btn-save px-0 w-100 text-normal`} onClick={() => {
                                                        setTranscribedText(editedTranscribedText)
                                                        setIsEditingTranscription(false)
                                                    }}>
                                                        Salvar edição
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="recording-revised-text mt-4">{transcribedText}</div>
                                    <div 
                                        className="row pt-4 align-items-end h-100 gx-md-5 gx-4"
                                        style={{
                                            position: 'sticky',
                                            bottom: stickyButtonsBottomStyle
                                        }}
                                    >
                                        <div className="appointment-fixed-buttons col-12 py-3">
                                            <div className="row">
                                                <div className="col pe-0">
                                                    <button className={`vsbox-btn vsbox-btn-dark px-0 w-100 text-normal`} onClick={() => {
                                                        setIsEditingTranscription(true)
                                                    }}>
                                                        <IconPrimaryEdit className="me-2" width="24" height="24" />
                                                        Editar
                                                    </button>
                                                </div>
                                                <div className="col pe-0">
                                                    <button className={`vsbox-btn vsbox-btn-save px-0 w-100 text-normal`} onClick={() => {
                                                        setConfirmationScreen(
                                                            <AppointmentFormAnamnesisRecordSaveConfirmation
                                                                onConfirm={() => {
                                                                    onSaveRecording(transcribedText)
                                                                    setConfirmationScreen(null)
                                                                }}
                                                                onCancel={() => setConfirmationScreen(null)}
                                                            />
                                                        )
                                                    }}>
                                                        <IconSave className="me-2" width="24" height="24" fill="#ffffff" />
                                                        Salvar
                                                    </button>
                                                </div>
                                                <div className="col-auto">
                                                    <button className={`vsbox-btn vsbox-btn-warning px-2 w-100 text-normal`} onClick={() => {
                                                        setConfirmationScreen(
                                                            <AppointmentFormAnamnesisRecordDeleteConfirmation
                                                                onDelete={() => {
                                                                    resetEverything()
                                                                    setConfirmationScreen(null)
                                                                }}
                                                                onCancel={() => setConfirmationScreen(null)}
                                                            />
                                                        )
                                                    }}>
                                                        <IconRecordDelete width="24" height="24" fill="var(--vsprontuario-warning-color)" />
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            )}

                        </div>
                    </div>
                ) : (
                    <div className="row pt-4">
                        <div className="anamnesis-record-text pb-3">
                            Antes de iniciar, verifique se o seu microfone está corretamente configurado e funcionando.
                        </div>
                        {!isRecording ? (
                            <div className="col-12">
                                <button className={`vsbox-btn vsbox-btn-primary w-100 text-normal`} onClick={() => {
                                    setWaitingForMediaRecorder(true)
                                    setGenericLoading(true)
                                    navigator.mediaDevices.getUserMedia(mediaRecorderConstraints).then(onMediaRecorderSuccess, onMediaRecorderError)
                                    setTimeout(() => setGenericLoading(false), 500) // NOTE: prevent permission screen from showing before checking if there is permission
                                }}>
                                    <IconRecordInit className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                    Iniciar gravação
                                </button>
                            </div>
                        ) : (
                            <>
                                <div className="col pe-0">
                                    <button className={`vsbox-btn vsbox-btn-negative px-0 w-100 text-normal`} onClick={() => {
                                        if (isPaused) {
                                            setIsPaused(false)
                                        } else {
                                            setIsPaused(true)
                                        }
                                        saveOnTracker("Clicou", "Assistente", "Gravar Anamnese", null, "Consulta", null, null, {
                                            appointmentId: appointment?.id ? appointment?.id : 'novo',
                                            patientId: patient?.id || patient?.objectId,
                                            action: isPaused ? "Retomar" : "Pausar"
                                        })
                                    }}>
                                        {isPaused ? (
                                            <>
                                                <IconRecordInit className="me-2" width="24" height="24" fill="var(--vsprontuario-negative-color)" />
                                                Retomar
                                            </>
                                        ) : (
                                            <>
                                                <IconRecordPause className="me-2" width="24" height="24" fill="var(--vsprontuario-negative-color)" />
                                                Pausar
                                            </>
                                        )}
                                    </button>
                                </div>
                                <div className="col pe-0">
                                    <button className={`vsbox-btn vsbox-btn-primary px-0 w-100 text-normal`} onClick={stopRecording}>
                                        <IconRecordStop className="me-2" width="24" height="24" fill="var(--vsprontuario-primary-color)" />
                                        Finalizar
                                    </button>
                                </div>
                                <div className="col-auto">
                                    <button className={`vsbox-btn vsbox-btn-warning px-2 w-100 text-normal`} onClick={() => {
                                        saveOnTracker("Clicou", "Assistente", "Gravar Anamnese", null, "Consulta", null, null, {
                                            appointmentId: appointment?.id ? appointment?.id : 'novo',
                                            patientId: patient?.id || patient?.objectId,
                                            action: "Excluir"
                                        })
                                        resetEverything()
                                    }}>
                                        <IconRecordDelete width="24" height="24" fill="var(--vsprontuario-warning-color)" />
                                    </button>
                                </div>
                            </>
                        )}
                        <div className="col-12">
                            <div className="row align-items-stretch justify-content-center recording-preview mt-4 gx-0">
                                <div className="col-auto flex-shrink-0 recording-preview-icon">
                                    <IconRecordMicro width="32" height="32" fill="#ffffff" />
                                </div>
                                <div className="col-auto recording-preview-bar d-flex ps-2">
                                    <IconRecordBar fill="var(--vsprontuario-gray-color-light)" />
                                    <div className="recording-filled-bar" ref={audioBar}>
                                        <IconRecordBar fill="var(--vsprontuario-primary-color)" />
                                    </div>
                                </div>
                            </div>
                            <div className="recording-audio-timer my-3 w-100 text-center">{formatTimer(audioTimer)} / {formatTimer(recordingMaxSeconds)}</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default AppointmentFormAnamnesisRecordPreview